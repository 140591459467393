@import '../css/functions/rem-fn'

.bs-select-user-to-switch
  inline-size: 100%
  text-align: left

  hr
    margin-block: rem-fn(30)

  .bs-input-container
    margin-block-end: rem-fn(20)
    max-inline-size: 100%

  .author-info
    min-inline-size: rem-fn(200)

  &__action
    justify-items: flex-end
    align-items: flex-end

    .q-btn__wrapper
      block-size: rem-fn(26)

  .bs-scroll-area
    padding-inline-end: 0

  .q-item
    padding-inline: 0

    &__label
      overflow-wrap: anywhere
